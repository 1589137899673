<template>
  <card-component class="has-table" title="Incidents (new)" icon="alert" :is-scrollable="true" :has-button-slot="true" :has-footer-slot="true" @ps-ready="psReady">
    <refresh-button slot="button" :is-loading="isLoading" @button-click="fetchData(1)"/>
      <card-toolbar v-if="this.status.loading" slot="toolbar" class="is-upper" :class="(status.toolbarClass)?status.toolbarClass:null">
        <div v-if="status.text" slot="left">{{ status.text }}</div>
        <span v-if="(status.label || status.labelIcon)" class="tag" :class="status.labelClass" slot="right">
        <b-icon v-if="status.labelIcon" :icon="status.labelIcon" custom-size="default"/>
        <span v-if="status.label">{{ status.label }}</span>
      </span>
      </card-toolbar>

      <div class="media-list ml-2 mr-2">
        <b-loading :is-full-page="false" :active="isLoading"/>
        <incident-item style="cursor: pointer" v-for="(incident, i) in incidents" :key="i" :alert="incident"
                       :project_id="package ? package.project.id : project.id"
                       v-on:click.native.stop="$router.push({
                        name:'project.incident',
                        params: {id: project.id, i_id: incident.id, project_name: project.name},
                        replace: true
                      })"/>
        <div v-if="!isLoading && incidents.length === 0" class="pt-3">
          <p class="has-text-centered has-text-grey-dark">No alerts found</p>
        </div>
      </div>
    <div v-if="this.package">
      <p  v-if="!this.package.project" class="has-text-centered has-text-grey-dark">Package must be assigned to project to view alerts</p>
    </div>


    <a v-if="this.page < this.last_page" class="card-footer-item" slot="footer" @click.prevent="loadMore">
      <b-icon icon="autorenew" custom-size="default"/>
      <span>Load more</span>
    </a>
  </card-component>
</template>

<script>
import CardComponent from '@/components/CardComponent'
import CardToolbar from '@/components/CardToolbar'
import AlertItem from '@/components/package/AlertItem'
import RefreshButton from '@/components/RefreshButton'
import packages from "@/store/modules/packages.store"
import AlertDetailComponent from "./AlertDetailComponent";
import IncidentItem from "@/components/package/IncidentItem";
import moment from 'moment'

export default {
  name: 'IncidentListComponent',
  components: {IncidentItem, AlertItem, AlertDetailComponent, RefreshButton, CardToolbar, CardComponent },
  props: {
    package: {
      type: Object,
      default: null
    },
    project: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      isLoading: false,
      incidents: [],
      status: {loading: true},
      page: 1,
      last_page: 2
    }
  },
  created() {
    if(!packages.isRegistered) {
      this.$store.registerModule('packages', packages)
      packages.isRegistered = true
    }
  },
  mounted () {
    this.delayedFetch()
  },
  methods: {
    psReady (ps) {
      this.ps = ps
    },

    /*
    * Fetch the already loaded alerts for the first time,
    * then search if there are new ones.
    * */
    delayedFetch () {
      this.isLoading = true
      this.fetchData(0)
      //this.fetchData(1)
    },
    fetchData (sync) {

      if(!sync){
        this.isLoading = true
      } else {
        this.status = {
          loading: true,
          text: 'Fetching new data...',
          labelClass: 'is-primary',
          labelIcon: 'refresh'
        }
      }
      let payload
      if(this.package){
        if(!this.package.project ) {
          this.isLoading = false
          this.status.loading = false
          return
        }
        payload = {
          project_id: this.project.id,
          params: `?include=eventsCount,recordingsCount&page=${this.page}` // TODO extra sync?
        }
      }
      else {
        //Project details
        payload = {
          project_id: this.project.id,
          params: `?include=eventsCount,recordingsCount&page=${this.page}` // Turn off sync
        }
      }

      this.$store.dispatch('packages/fetchProjectIncidents', payload )
        .then(response => {
          if (sync) {
            this.incidents.unshift(...response.data.data)
          } else {
            this.incidents.push(...response.data.data)
            if(this.incidents.length > 0 && this.incidents[0].ongoing ) {

              this.$buefy.dialog.confirm({
                title: 'Incident ongoing!',
                message: 'An alarm was triggered ' +  moment(this.incidents[0].updated_at).fromNow() +
                  '. Click the button below to view details/recordings.',
                confirmText: 'View Incident',
                type: 'is-danger',
                hasIcon: true,
                onConfirm: () => this.$router.push({
                  name:'project.incident',
                  params: {id: this.project.id, i_id: this.incidents[0].id, project_name: this.project.name},
                  replace: true
                })
              })
            }
          }
          this.ps.update();
          this.isLoading = false

          if (sync) {
            this.status = {
              loading: false,
              text: 'Alerts up-to-date',
              labelClass: 'is-success',
              labelIcon: 'check'
            }
          } else {
            this.last_page = response.data.meta.last_page
          }
        })

    },
    loadMore () {
      if(this.page <= this.last_page) {
        this.page += 1
        this.fetchData(0)
        setTimeout(() => {
          const container = this.$el.querySelector('.ps');
          container.scrollTop = container.scrollHeight;
        }, 0);
      }
    },
  }
}
</script>

<style lang="scss">


</style>
