import { render, staticRenderFns } from "./AlarmDetailComponent.vue?vue&type=template&id=2db581d3&scoped=true&"
import script from "./AlarmDetailComponent.vue?vue&type=script&lang=js&"
export * from "./AlarmDetailComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2db581d3",
  null
  
)

export default component.exports