<template>
  <card-component class="has-table" title="Alerts" icon="alert" :is-scrollable="true" :has-button-slot="true" :has-footer-slot="true" @ps-ready="psReady">
    <refresh-button slot="button" :is-loading="isLoading" @button-click="fetchData(1)"/>
      <card-toolbar v-if="this.status.loading" slot="toolbar" class="is-upper" :class="(status.toolbarClass)?status.toolbarClass:null">
        <div v-if="status.text" slot="left">{{ status.text }}</div>
        <span v-if="(status.label || status.labelIcon)" class="tag" :class="status.labelClass" slot="right">
        <b-icon v-if="status.labelIcon" :icon="status.labelIcon" custom-size="default"/>
        <span v-if="status.label">{{ status.label }}</span>
      </span>
      </card-toolbar>

      <div class="media-list ml-2 mr-2">
        <b-loading :is-full-page="false" :active="isLoading"/>
        <alert-item style="cursor: pointer" v-for="(alert, i) in alerts" :key="i" :alert="alert" :project_id="package ? package.project.id : project.id" v-on:click.native.stop="alertModal(alert.id)"/>
        <div v-if="!isLoading && alerts.length === 0" class="pt-3">
          <p class="has-text-centered has-text-grey-dark">No alerts found</p>
        </div>
      </div>
    <div v-if="this.package">
      <p  v-if="!this.package.project" class="has-text-centered has-text-grey-dark">Package must be assigned to project to view alerts</p>
    </div>


    <a v-if="this.page < this.last_page" class="card-footer-item" slot="footer" @click.prevent="loadMore">
      <b-icon icon="autorenew" custom-size="default"/>
      <span>Load more</span>
    </a>
  </card-component>
</template>

<script>
import CardComponent from '@/components/CardComponent'
import CardToolbar from '@/components/CardToolbar'
import AlertItem from '@/components/package/AlertItem'
import RefreshButton from '@/components/RefreshButton'
import packages from "@/store/modules/packages.store"
import AlertDetailComponent from "./AlertDetailComponent";


export default {
  name: 'AlertListComponent',
  components: { AlertItem, AlertDetailComponent, RefreshButton, CardToolbar, CardComponent },
  props: {
    package: {
      type: Object,
      default: null
    },
    project: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      isLoading: false,
      alerts: [],
      status: {loading: true},
      page: 1,
      last_page: 2
    }
  },
  created() {
    if(!packages.isRegistered) {
      this.$store.registerModule('packages', packages)
      packages.isRegistered = true
    }
  },
  mounted () {
    this.delayedFetch()
  },
  methods: {
    psReady (ps) {
      this.ps = ps
    },

    /*
    * Fetch the already loaded alerts for the first time,
    * then search if there are new ones.
    * */
    delayedFetch () {
      this.isLoading = true
      this.fetchData(0)
      this.fetchData(1)
    },
    fetchData (sync) {

      if(!sync){
        this.isLoading = true
      } else {
        this.status = {
          loading: true,
          text: 'Fetching new data...',
          labelClass: 'is-primary',
          labelIcon: 'refresh'
        }
      }
      if(this.package){
        if(!this.package.project ) {
          this.isLoading = false
          this.status.loading = false
          return
        }
        let payload = {
          package_id: this.package.id,
          project_id: this.package.project.id,
          params: `&page=${this.page}&fetchNew=${sync}`
        }
        this.$store.dispatch('packages/fetchPackageAlerts', payload )
          .then(response => {
            if(sync) {
              this.alerts.unshift(...response.data.data)
            } else {
              this.alerts.push(...response.data.data)
            }
            this.ps.update();
            this.isLoading = false

            if(sync) {
              this.status = {
                loading: false,
                text: 'Alerts up-to-date',
                labelClass: 'is-success',
                labelIcon: 'check'
              }
            }
            else {
              this.last_page = response.data.meta.last_page
            }

          })
      }
      else {
        //Project details
        let payload = {
          project_id: this.project.id,
          params: `?&page=${this.page}&fetchNew=${sync}` // Turn off sync
        }
        this.$store.dispatch('packages/fetchProjectAlerts', payload )
          .then(response => {
            if (sync) {
              this.alerts.unshift(...response.data.data)
            } else {
              this.alerts.push(...response.data.data)
            }
            this.ps.update();
            this.isLoading = false

            if (sync) {
              this.status = {
                loading: false,
                text: 'Alerts up-to-date',
                labelClass: 'is-success',
                labelIcon: 'check'
              }
            } else {
              this.last_page = response.data.meta.last_page
            }
          })
      }
    },
    loadMore () {
      if(this.page <= this.last_page) {
        this.page += 1
        this.fetchData(0)
        setTimeout(() => {
          const container = this.$el.querySelector('.ps');
          container.scrollTop = container.scrollHeight;
        }, 0);
      }
    },

    alertModal(id) {
      if(this.$gates.hasAnyPermission(`${this.project ? this.project.id : this.package.project.id}.play recordings|play all recordings`) || this.$gates.isSuperUser()) {
        let thisIns = this
        this.$buefy.modal.open({
          parent: this,
          component: AlertDetailComponent,
          props: {
            project_id: this.project ? this.project.id : this.package.project.id,
            alert_id: id
          },
          hasModalCard: true,
          customClass: 'custom-class custom-class-2',
          trapFocus: true,
          ariaRole: 'dialog',
          width: 500,
          events: {
            refresh () {
              thisIns.fetchData(0);
            }
          }
        })
      }
      else {
        this.$buefy.toast.open("You don't have permission to play recordings.")
      }
    }
  }
}
</script>

<style lang="scss">


</style>
