<template>
  <div class="tile is-parent">
    <div class="tile is-child">
      <div class="columns is-multiline">
        <article class="column is-full-desktop is-half-fullhd">
          <card-component :has-footer-slot="true">
            <div class="media-list">
              <div v-for="(partition, index) in partitions" :key="index" class="media">
                <b-loading :is-full-page="false" v-model="isLoading"></b-loading>
                <div class="media-content">
                  <div class="level is-mobile mb-2">
                    <div class="level-left">
                      <!--                            <div class="level-item" style="vertical-align: top">-->
                      <!--                                <status-indicator style="margin-right:  0.5rem; &#45;&#45;status-indicator-size: 15px;" :status="partitionStatus(partition).indicator" :pulse="partitionStatus(partition).pulse" />-->
                      <!--                            </div>-->

                      <div class="level-item has-text-left" style="flex-shrink: 1">
                        <b-icon
                            class="mb-3 mr-3"
                            size="is-large"
                            :icon="partitionStatus(partition).icon"
                            :type="partitionStatus(partition).color"
                        ></b-icon>

                        <div>
                          <p class="mb-0 py-0">
                          <span
                              style="margin-right: 0.7rem"
                              class="title is-5"
                          >{{ partition.name }}</span
                          >
                          </p>
                          <p class="py-0">
                            <small
                                v-bind:class="partitionStatus(partition).type"
                                class="is-5"
                            >{{ partitionStatus(partition).msg }}</small
                            >
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="level-right">
                      <!--                            <div class="level-item buttons">-->
                      <!--                                <b-button @click="arm(partition)" :disabled="partition.ready_to_arm !== 1" size="is-small" :type="partition.armed ? 'is-danger' : 'is-danger'" icon-left="lock">arm</b-button>-->
                      <!--                                <b-button @click="disarm(partition)" :disabled="partition.armed !== 1" size="is-small" :type="partition.armed ? 'is-grey' : 'is-success'" icon-left="lock-open">disarm</b-button>-->
                      <!--                            </div>-->
                      <div class="level-item">
                        <b-field class="level-item">
                          <p class="control">
                            <b-button
                                rounded
                                @click="arm(partition)"
                                v-permission:has.disabled="`${project.id}.arm partitions|arm all partitions`"
                                :disabled="
                                  partition.ready_to_arm !== 1 ||
                                  partition.armed === 1
                                "
                                size="is-small"
                                :type="partition.armed ? 'is-danger' : 'is-grey'"
                                icon-left="lock"
                            ></b-button
                            >
                          </p>
                          <p class="control">
                            <b-button
                                rounded
                                @click="disarm(partition)"
                                v-permission:any.has.disabled="`${project.id}.disarm partitions|disarm all partitions`"
                                :disabled="partition.armed !== 1"
                                size="is-small"
                                :type="partition.armed ? 'is-grey' : 'is-success'"
                                icon-left="lock-open-variant"
                            ></b-button
                            >
                          </p>
                        </b-field>
                      </div>
                    </div>
                  </div>

                  <div class="">
                    <div
                        v-for="zone in partition.zones"
                        class="level is-mobile mt-2 mb-4"
                        v-if="zone.used && !zone.power_monitor"
                    >
                      <div class="level-left">
                        <div class="level-item">
                          <status-indicator
                              style="display: inline-block; margin-right: 0.4rem"
                              :status="zoneStatus(zone).indicator"
                              :pulse="zoneStatus(zone).pulse"
                          />
                          <small class="hast-text">{{ zone.name }}</small>
                        </div>
                      </div>
                      <div class="level-right">
                        <div class="level-item">
                          <b-tag :type="zoneStatus(zone).type">{{
                              zoneStatus(zone).msg
                            }}
                          </b-tag>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <a
                v-permission:any="`${project.id}.view schemas|manage all schemas|${project.id}.view exceptions|manage all exceptions`"
                class="card-footer-item"
                slot="footer"
                @click.prevent="
              $router.push({
                name: 'project.schemes',
                params: { project_id: project.id },
              })
            "
            >
              <b-icon icon="lock-clock" custom-size="default"/>
              <span>View Schemas & Exceptions</span>
            </a>
          </card-component>
        </article>
        <article class="column">
          <card-component :has-footer-slot="true">
            <div class="media-list">
              <div class="media">
                <b-loading :is-full-page="false" v-model="isLoading"></b-loading>
                <div class="media-content">
                  <div class="level level-is-shrinkable is-mobile mb-2">
                    <div class="level-left">
                      <!--                            <div class="level-item" style="vertical-align: top">-->
                      <!--                                <status-indicator style="margin-right:  0.5rem; &#45;&#45;status-indicator-size: 15px;" :status="partitionStatus(partition).indicator" :pulse="partitionStatus(partition).pulse" />-->
                      <!--                            </div>-->
                      <div class="level-item has-text-left">
                        <b-icon
                            v-if="status.power_status === 'powered'"
                            class="mb-3"
                            size="is-large"
                            :icon="status.generator_mode ? 'engine' : 'power-plug'"
                            :type="status.online ? 'is-success' : 'is-grey-light'"
                        ></b-icon>
                        <b-icon
                            v-else-if="status.power_status === 'no power'"
                            class="mb-3"
                            size="is-large"
                            :icon="status.generator_mode ? 'engine-off' : 'power-plug-off'"
                            :type="status.online ? 'is-danger' : 'is-grey-light'"
                        ></b-icon>
                        <b-icon
                            v-else
                            class="mb-3"
                            size="is-large"
                            :icon="status.generator_mode ? 'engine-outline' : 'power-plug-outline'"
                            type="is-grey"
                        ></b-icon>
                      </div>
                      <div class="level-item">
                        <div>
                          <p class="mb-0 py-0">
                          <span
                              v-if="status.power_status === 'powered'"
                              style="margin-right: 0.7rem"
                              class="title is-5"
                          >Powered</span
                          >
                            <span
                                v-else-if="status.power_status === 'no power'"
                                style="margin-right: 0.7rem"
                                class="title is-5"
                            >No power</span
                            >
                            <span
                                v-else
                                style="margin-right: 0.7rem"
                                class="title is-5"
                            >Power unknown</span
                            >
                          </p>
                          <p class="py-0">
                            <small class="is-5 has-text-grey-dark">
                              Last checked {{ status.power_timestamp | moment("from", "now") }}.
                            </small>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="level-right"></div>
                  </div>

                  <b-message
                      v-if="status.generator_mode && status.power_restored_at"
                      type="is-warning is-light"
                  >
                    Generator has been running for {{ status.power_restored_at | moment("from", "now", true) }} (since {{ status.power_restored_at }}).
                  </b-message>

                  <b-message
                      v-else-if="status.power_lost_at"
                      type="is-danger is-light"
                  >
                    Power has been lost at {{ status.power_lost_at }} ({{
                      status.power_lost_at | moment("from", "now")
                    }}).
                  </b-message>

                  <div v-if="power_partitions.length > 0">
                    <div
                        v-for="zone in power_partitions[0].zones"
                        class="level is-mobile mt-2 mb-2"
                        v-if="zone.used"
                    >
                      <div class="level-left">
                        <div class="level-item">
                          <status-indicator
                              style="display: inline-block; margin-right: 0.4rem"
                              :status="zoneStatus(zone).indicator"
                              :pulse="zoneStatus(zone).pulse"
                          />
                          <small class="hast-text">{{ zone.name }}</small>
                        </div>
                      </div>
                      <div class="level-right">
                        <div class="level-item pl-0 mr-0">
                          <b-tag :type="zoneStatus(zone).type">{{
                              zoneStatus(zone).msg
                            }}
                          </b-tag>
                        </div>
                      </div>
                    </div>
                    <div class="level is-mobile mt-3 ">
                      <div class="level-left">
                        <div class="level-item">
                          <b-icon
                              class=""
                              style="margin-right: 0.4rem"
                              size="small"
                              icon="battery-70"
                              type="is-grey-light"
                          ></b-icon>
                          <small class="has-text-grey-light">Battery</small>
                        </div>
                      </div>
                      <div class="level-right">
                        <div class="level-item">
                          <b-tag type=" is-light">coming soon</b-tag>
                        </div>
                      </div>
                    </div>

                  </div>


                </div>
              </div>
            </div>
          </card-component>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
import CardComponent from "@/components/CardComponent";
import alarm from "../../store/modules/alarm.store";

export default {
  name: "AlarmDetailComponent",
  components: {CardComponent},
  props: ["alarm", "status", "project"],

  data() {
    return {
      autorefresh: true,
      isLoading: true,
      partitions: [],
      power_partitions: [],
    };
  },

  created() {
    if (!alarm.isRegistered) {
      this.$store.registerModule("alarm", alarm);
      alarm.isRegistered = true;
    }
  },

  mounted() {
    this.fetchData();
    this.intervalId = window.setInterval(() => {
      this.fetchDataSilent();
    }, 2000);
  },
  beforeDestroy: function () {
    clearInterval(this.intervalId);
  },

  methods: {
    fetchData() {
      this.isLoading = true;

      this.partitions = [];

      let payload = {
        alarm_id: this.alarm.id,
        params: "include=partitions.zones",
      };
      this.$store
          .dispatch("alarm/fetchAlarmDetails", payload)
          .then((response) => {
            let result = JSON.parse(JSON.stringify(response.data.data));
            this.partitions = result.partitions.filter(function (e) {
              return e.exist && !e.power_partition;
            });
            this.power_partitions = result.partitions.filter(function (e) {
              return e.exist && e.power_partition;
            });

            this.isLoading = false;
          });
    },
    fetchDataSilent() {
      let payload = {
        alarm_id: this.alarm.id,
        params: "include=partitions.zones",
      };
      // TODO andere endpoint!
      this.$store
          .dispatch("alarm/fetchAlarmDetails", payload)
          .then((response) => {
            let result = JSON.parse(JSON.stringify(response.data.data));
            this.partitions = result.partitions.filter(function (e) {
              return e.exist && !e.power_partition;
            });
            this.power_partitions = result.partitions.filter(function (e) {
              return e.exist && e.power_partition;
            });
          });
    },

    armOrDisarm(partition) {
      if (partition.armed === 1) {
        this.disarm(partition);
      } else if (partition.ready_to_arm === 1) {
        this.arm(partition);
      }
    },

    arm(partition) {
      this.isLoading = true;
      let payload = {
        alarm_id: this.alarm.id,
        partition_id: partition.id,
      };
      this.$store
          .dispatch("alarm/armPartition", payload)
          .then((response) => {
            let message = JSON.parse(JSON.stringify(response.data.message));
            this.isLoading = false;
            this.$buefy.toast.open({
              message: message,
              type: "is-success",
            });
            let payload = {
              alarm_id: this.alarm.id,
              params: "include=partitions.zones",
            };
            // TODO andere endpoint!
            setTimeout(() => {
              this.$store
                  .dispatch("alarm/fetchAlarmDetails", payload)
                  .then((response) => {
                    let result = JSON.parse(JSON.stringify(response.data.data));
                    this.partitions = result.partitions.filter(function (e) {
                      return e.exist;
                    });
                  });
            }, 1000);
          })
          .catch((error) => {
            this.$buefy.toast.open({
              message: error.response.data.message,
              type: "is-danger",
            });
            this.isLoading = false;
            this.$store
                .dispatch("alarm/fetchAlarmDetails", payload)
                .then((response) => {
                  let result = JSON.parse(JSON.stringify(response.data.data));
                  this.partitions = result.partitions.filter(function (e) {
                    return e.exist;
                  });
                });
          });
    },
    disarm(partition) {
      let payload = {
        alarm_id: this.alarm.id,
        partition_id: partition.id,
      };
      this.isLoading = true;
      this.$store
          .dispatch("alarm/disarmPartition", payload)
          .then((response) => {
            let message = JSON.parse(JSON.stringify(response.data.message));
            this.isLoading = false;

            this.$buefy.toast.open({
              message: message,
              type: "is-success",
            });
            let payload = {
              alarm_id: this.alarm.id,
              params: "include=partitions.zones",
            };

            // TODO Andere endpoint!
            setTimeout(() => {
              this.$store
                  .dispatch("alarm/fetchAlarmDetails", payload)
                  .then((response) => {
                    let result = JSON.parse(JSON.stringify(response.data.data));
                    this.partitions = result.partitions.filter(function (e) {
                      return e.exist;
                    });
                  });
            }, 1000);
          })
          .catch((error) => {
            this.isLoading = false;

            this.$buefy.toast.open({
              message: error.response.data.message,
              type: "is-danger",
            });
            this.$store
                .dispatch("alarm/fetchAlarmDetails", payload)
                .then((response) => {
                  let result = JSON.parse(JSON.stringify(response.data.data));
                  this.partitions = result.partitions.filter(function (e) {
                    return e.exist;
                  });
                });
          });
    },
    partitionStatus(partition) {
      let result = {}
      if (partition.ready_to_arm === 1 && partition.armed === 0) {
        result = {
          type: "has-text-success",
          indicator: "positive",
          pulse: true,
          msg: "Ready to arm",
          icon: "lock-open-variant",
          color: "is-success",
        };
      } else if (partition.armed === 1 && partition.triggered === 0) {
        result = {
          type: "has-text-danger",
          indicator: "negative",
          pulse: false,
          msg: "Armed",
          icon: "lock",
          color: "is-danger",
        };
      } else if (partition.triggered === 1) {
        result = {
          type: "has-text-danger",
          indicator: "negative",
          pulse: true,
          msg: "Triggered",
          icon: "lock-alert",
          color: "is-danger",
        };
      } else {
        result = {
          type: "has-text-warning",
          indicator: "intermediary",
          pulse: false,
          msg: "Not ready",
          icon: "lock-open-variant-outline",
          color: "is-warning",
        };
      }

      if (!this.status.online) {
        result.indicator = "";
        result.color = "is-grey-light";
        result.type = "has-text-grey-light"
      }


      return result;
    },
    zoneStatus(zone) {
      let result = {}
      if (!zone.power_monitor) {
        if (zone.open === 1 && !zone.triggered) {
          result = {
            indicator: "intermediary",
            type: "is-warning is-light",
            msg: "not ready",
            pulse: false,
          };
        } else if (zone.armed === 1 && !zone.triggered) {
          result = {
            indicator: "negative",
            type: "is-danger is-light",
            msg: "armed",
            pulse: false,
          };
        } else if (zone.triggered === 1) {
          result = {
            indicator: "negative",
            type: "is-danger is-light",
            msg: "triggered",
            pulse: true,
          };
        } else {
          result = {
            indicator: "positive",
            type: "is-success is-light",
            msg: "ready",
            pulse: false,
          };
        }
      } else {
        if (zone.open === 1) {
          result = {
            indicator: "danger",
            type: "is-danger is-light",
            msg: "not powered",
            pulse: false,
          };
        } else {
          result = {
            indicator: "positive",
            type: "is-success is-light",
            msg: "powered",
            pulse: false,
          };
        }
      }

      if (!this.status.online) {
        result.indicator = "";
        result.type = "is-grey is-light"
      }

      return result;
    },
  },
};
</script>

<style scoped>

</style>
