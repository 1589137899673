<script src="../markerStyles.js"></script>
<template>
  <div style="height: 100%;">

    <nav v-if="connection" class="level mx-5 mt-5 mb-4">
      <div class="level-left">
        <a @click="$router.go(-1)" class="level-item">
          <b-icon type="is-grey-darker" icon="arrow-left"></b-icon>
        </a>
        <div class="level-item">
          <p class="subtitle is-5">
            {{ connection.name }}
          </p>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <b-tag :type="connection.status.color"
                 close-type='is-warning'
                 close-icon-type='is-dark'
                 attached
                 :close-icon='connection.status.icon'>
            {{ connection.status.name }}
          </b-tag>
        </div>
      </div>
    </nav>
    <hr class="mb-1">

    <b-tabs class="px-5 pb-2" style="height: 100%">
      <b-tab-item label="Details">
        <form v-if="connection" @submit.prevent="updateConnection">

          <b-field label="Short Name">
            <b-input v-model="connection.name"></b-input>
          </b-field>
          <b-field label="Status">
            <b-select v-model="connection.status.id" expanded>
              <option :value="1">Draft</option>
              <option :value="2">Quoted</option>
              <option :value="3">Planned</option>
              <option :value="4">Placed</option>
              <option :value="5">Tested</option>
              <option :value="6">Error</option>
            </b-select>
          </b-field>
          <b-field label="Length">
            <p>
              {{ length }} metres
            </p>
          </b-field>
          <b-field label="Item Category">
            <treeselect v-model="connection.category.id"
                        :multiple="false"
                        :show-count="true"
                        :options="categories"/>
          </b-field>
          <b-field label="Device location">
            <b-input v-model="connection.location"></b-input>
          </b-field>
          <b-field label="Level">
            <b-numberinput v-model="connection.level"></b-numberinput>
          </b-field>

          <hr>
          <div class="buttons">
            <b-button type="is-info" native-type="submit" expanded>Update Connection</b-button>
          </div>
        </form>
      </b-tab-item>

      <b-tab-item label="Activity">
        <CommentListComponent type="Connection" :id="this.$route.params.id"></CommentListComponent>
      </b-tab-item>

      <b-tab-item label="Hierarchy">
        Lorem <br>
        ipsum <br>
        dolor <br>
        sit <br>
        amet.
      </b-tab-item>

    </b-tabs>

  </div>
</template>
<script>

import CardComponent from '@/components/CardComponent'
import MapItemNamechangeComponent from "@/components/deplo/components/MapItemNamechangeComponent";
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import axios from 'axios'
import CommentListComponent from "@/components/deplo/components/CommentListComponent";
import length from '@turf/length'

export default {
  name: 'ConnectionDetailSidebar',
  props: {
    selected: {
      default: null
    },
    visible: {
      default: true
    },
    isCreating: {
      default: true
    }
  },
  components: {
    CommentListComponent,
    CardComponent,
    Treeselect,
  },
  data() {
    return {
      loading: false,
      select_toast: null,
    }
  },
  computed: {
    connection() {
      return this.$store.getters["deplo/getConnection"](this.$route.params.id)
    },

    categories() {
      return this.$store.state.deplo.categories
    },

    store_selected() {
      return this.$store.state.deplo.selectedFeatures
    },

    length() {
      return Math.round(length(this.connection.geo)*1000)
    }

  },
  beforeMount() {
    //this.fetchCategories()
    this.$store.dispatch("deplo/fetchConnection", this.$route.params.id)
  },
  watch: {
    '$route'(to, from) {
      this.$store.dispatch("deplo/fetchConnection", this.$route.params.id)
    },

  },
  methods: {

    async updateConnection() {
      //TODO finish this copy pasta
      let updated_item = this.connection
      updated_item.category_id = this.connection.category.id
      updated_item.status_id = this.connection.status.id

      this.$store.dispatch('deplo/updateConnection', {update: true, formData: updated_item}).then(response => {
        this.$buefy.toast.open({
          message: 'Connection updated',
          type: 'is-success'
        })
      })

    },

    selectFeature() {
      this.select_toast = this.$buefy.toast.open({
        message: 'Select a feature on the map as parent...',
        indefinite: true,
      })
      this.$store.commit('deplo/setWaitingForSelection', true)

    }
  }
}
</script>

<style>

.vue-treeselect__control {
  height: 40px;
}

.b-tabs .tab-content {
  padding: 0;
  padding-top: 1rem;
  margin-left: 0;
}

</style>
